import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Newsletter from "../components/homepage/newsletter"
import InfiniteScroll from "react-infinite-scroll-component"
import LoadingAnimation from "../components/loading-animation"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"

function Shop() {
  const { prismicShop, allPrismicCategory } = useStaticQuery(graphql`
    query ShopQuery {
      prismicShop {
        _previewable
        data {
          title {
            html
            text
          }
          body {
            text
            html
          }
          seo_title {
            html
            text
          }
          seo_description {
            html
            text
          }
          items {
            image {
              alt
              url
            }
            name {
              text
              html
            }
            subtitle {
              text
              html
            }
            description {
              text
              html
            }
            shop_link {
              url
            }
            category {
              uid
              raw
            }
          }
        }
      }
      allPrismicCategory(filter: { data: { show_in_shop: { eq: true } } }) {
        nodes {
          id
          data {
            show_in_shop
            name
          }
          uid
        }
      }
    }
  `)

  const { title, body, seo_title, seo_description, items } = prismicShop.data

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    className: "category-slider",
  }

  const [selectedCategory, setSelectedCategory] = useState(1)
  const [filteredItems, setFilteredItems] = useState([])
  const [categories, setcategories] = useState([])

  // pagination states
  const [paginationCount, setPaginationCount] = useState(10)
  const [paginationData, setPaginationData] = useState([])
  const [paginationHasMoreData, setPaginationHasMoreData] = useState(true)
  const paginationDefaultCount = 10
  const [loadInit, setLoadInt] = useState(true)

  useEffect(() => {
    setFilteredItems(items)
  }, [items])

  const onFilterItems = (category) => {
    setSelectedCategory(category.uid)
    if (category.uid === 1) {
      setPaginationData(items)
      setFilteredItems(items)
      return
    }
    var filter = items.filter((item) => {
      if (item.category?.uid === category?.uid) {
        return item
      }
      return false
    })
    setFilteredItems(filter)
    setPaginationData(filter)
  }

  useEffect(() => {
    const all = [
      {
        data: {
          show_in_shop: true,
          name: "See All",
        },
        id: 1,
        uid: 1,
      },
    ]
    setcategories(all.concat(allPrismicCategory.nodes))
  }, [])

  const fetchData = () => {
    setPaginationCount(paginationCount + paginationDefaultCount)
    setPaginationData(filteredItems.slice(0, paginationCount))
    if (paginationCount >= filteredItems.length) {
      setPaginationHasMoreData(false)
    }
  }

  if (loadInit) {
    setPaginationData(items.slice(0, paginationCount))
    setLoadInt(false)
  }

  return (
    <Layout>
      <SEO
        keywords={[
          `sympathy gifts`,
          `shop`,
          `memorial gifts`,
          `sympathy gift ideas`,
          `gift ideas`,
        ]}
        title={seo_title?.text}
        description={seo_description?.text}
        excludeSiteMetadataTitle
      />

      <div className="bg-grey-lighter">
        <div className="max-w-body mx-auto px-6 pb-8 xl:px-0">
          <div className="max-w-body pt-16 md:pt-24 pb-6 md:pb-8 xl:px-0">
            <div className="headline-text">{parse(title.html)}</div>
            <h4 className="sub-headline-text">{parse(body.html)}</h4>

              <div className="visible md:hidden">
                <div className="flex flex-row space-x-4 overflow-x-auto scrollbar-hide">
                  {categories?.map((category) => (
                    <div
                      onClick={() => onFilterItems(category)}
                      key={category.uid}
                      className={`flex items-center rounded-lg text-sm text-center
                      ${
                        selectedCategory === category.uid
                          ? "bg-secondary font-semibold"
                          : "bg-grey-light"
                      } leading-normal px-4 py-2 mt-2`}
                    >
                      <div>{category.data.name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="hidden md:flex">
                {categories?.map((category) => (
                  <div
                    onClick={() => onFilterItems(category)}
                    key={category.uid}
                    className={`rounded-lg text-sm leading-normal
                    ${
                      selectedCategory === category.uid
                        ? "bg-secondary font-semibold "
                        : "bg-grey-light"
                    }  px-4 py-2 mr-2 mt-2 cursor-pointer`}
                  >
                    {category.data.name}
                  </div>
                ))}
              </div>

          </div>
          <InfiniteScroll
            dataLength={paginationCount}
            next={fetchData}
            hasMore={paginationHasMoreData}
            loader={<LoadingAnimation />}
            endMessage={
              <p className="text-grey text-center font-extralight mb-24 mt-16">
                <b>You’ve reached the end!</b>
              </p>
            }
          >
            <div className="flex flex-row flex-wrap mx-0 md:mx-0 md:flex-row">
              {paginationData?.map((i) => (
                <div
                  className="shop-wrapper md:w-1/4 pb-6 md:pb-0"
                  key={i.name.text}
                >
                  <div className="flex flex-col mt-6 mb-4 md:mr-12">
                    <div className="flex justify-center w-48 h-48">
                      <img
                        alt={i.image.alt}
                        src={i.image.url}
                        className="object-contain"
                      ></img>
                    </div>
                    <div className="text-lg font-semibold leading-snug mb-2 mt-6">
                      {parse(i.name.html)}
                    </div>
                    <div className="text-sm text-grey-dark font-semibold leading-snug mb-1">
                      {parse(i.subtitle.html)}
                    </div>
                    <div className="text-sm text-grey-dark leading-normal">
                      {parse(i.description.html)}
                    </div>
                  </div>
                  <a
                    className="btn out-of-app -primary -small py-4 ml-0 shop-button"
                    href={i.shop_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shop Now
                  </a>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <Newsletter location="ShopPage Newsletter" />
    </Layout>
  )
}

Shop.propTypes = {
  data: PropTypes.object,
}

export default withPrismicPreview(Shop, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
